// Kebab case on flags
export const FLAGS = {
    TEST_FLAG: 'pro-test-flag',
    VENDOR_DISCOVERY_AI: 'pro-vendor-discovery-ai',
    PLATFORM_PASSWORD_RESET: 'pro-platform-password-reset',
    ACCOUNT_SPLIT_AUTO_ADJUSTMENTS: 'pro-requisition-line-item-account-split-auto-adjustments',
    SHOW_SERVICE_CLOUD_CHAT_BOT: 'pro-show-service-cloud-chat-bot',
    ENABLE_REACT_TABLE_8: 'pro-enable-react-table-8',
    ATTACHMENTS_SCANNING: 'pro-4363-attachment-scanning',
    ALL_PROPOSALS_REPORT: 'pro-all-proposals-report',
    SHOW_CERTIFICATIONS_IMPORT: 'pro-4468-enable-vendor-certifications-import',
    DOING_BUSINESS_AS: 'pro-5274-doing-business-as-field',
    ENABLE_CONTRACT_DOCUMENTS_TAB: 'pro-6888-create-new-documents-tab-on-contracts',
    ENABLE_E_SIGN_EXTERNAL_AGREEMENT: 'pro-7140-e-sign-external-agreement',
    ENABLE_VENDOR_LISTS_UPDATE: 'pro-5282-updated-vendor-lists',
    ENABLE_PUBLIC_CONTRACTS_TAB: 'pro-5387-public-contracts-tab',
    ENABLE_SINGLE_SECTION_EXPORT: 'pro-5862-enable-single-section-export',
    ENABLE_NEW_INTERNAL_VENDOR_MANAGEMENT_TABLE: 'pro-enable-new-internal-vendor-management-table',
    ENABLE_CONTRACT_PACKAGE_COMPILER: 'pro-6522-contract-package-compiler',
    ENABLE_MOVE_CONTRACTS_SUPPORT_TOGGLE_TO_SITE: 'pro-6755-move-contracts-support-toggle-to-site',
    EXPORT_BUTTON_V2: 'pro-6794-export-button-v-2',
    ENABLE_TEMPLATE_INGESTION_AI: 'pro-template-ingestion-ai',
    ENABLE_EMBEDDED_PAVILION_SEARCH: 'pro-7930-embedded-pavilion-search',
    ENABLE_MUI_DATE_PICKERS_IN_REQ: 'pro-8057-date-pickers-to-mui',
    ENABLE_TRIAGE_BASED_STEPS: 'pro-6593-triage-based-reviewer-assignment-in-workflow-sequence',
    ENABLE_OG_GRID: 'pro-8060-enable-og-grid',
    ENABLE_FLEXIBLE_PERCENTAGE_COLUMN: 'pro-7899-flexible-percentage-column-price-tables',
    ENABLE_ESIGNATURES_GOV_RESTRICTIONS: 'pro-enable-electronic-signatures-gov-restrictions',
    ENABLE_CONTRACTING_DOCUMENTS_GOV_RESTRICTIONS:
        'PRO-enable-contracting-documents-gov-restriction',
    ENABLE_CLOSED_SUBSTATUSES: 'pro-enable-closed-substatuses',
    ENABLE_CONTRACT_AUDIT_LOG: 'pro-enable-contract-audit-ui',
    ENABLE_CUSTOMIZING_REQUEST_WORKFLOW_MID_REQUEST:
        'pro-7968-customizing-request-workflow-mid-request',
    ENABLE_NEW_LOGO: 'update-pro-suite-with-new-logo',
    ENABLE_MOVE_CHATBOT_BUBBLE: 'pro-6953-move-chatbot-bubble',
    ENABLE_NEW_CONTRACTS_LANDING_PAGE: 'pro-8461-enable-new-contracts-landing-page',
};
